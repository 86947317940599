import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import workBg from "../../images/our-work-bg-vector.svg"

const OurWork = ({ data }) => {  
  const renderProject = (project, i) => {
    if (i % 2 === 0) {
      return (
        <div className="our_work_common_div" key={project.id}>
          <div className="our_work_img_col">
            <GatsbyImage image={getImage(project.image)} alt={project.title} />
          </div>
          <div className="our_work_content_col cm_margin_left">
            <div className="our_work_box">
              <h3>{project.title}</h3>
              <p>{renderRichText(project.description)}</p>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="our_work_common_div" key={project.id}>
          <div className="our_work_content_col cm_margin_right">
            <div className="our_work_box">
              <h3>{project.title}</h3>
              <p>{renderRichText(project.description)}</p>
            </div>
          </div>
          <div className="our_work_img_col">
            <GatsbyImage image={getImage(project.image)} alt={project.title} />
          </div>
        </div>
      )
    }
  }
  return (
    <div className="our_work_section">
      <div className="container">
        <div className="our_work_block">
          <div className="commn_title_label">
            {/* <p>{section6ShortText}</p> */}
          </div>
          <div className="common_inner_title">
            <h3>{data.section6Title && data.section6Title}</h3>
          </div>

          <div className="our_work_process_block">
            {data.section6Projects && data.section6Projects.length > 0 && data.section6Projects.map((project, i) =>
              renderProject(project, i)
            )}

            {/* <div className="common_section_btn"> 
              <Link
                to={section6Url}
                className="common_btn"
                style={{ display: "none" }}
              >
                {section6Label}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="38.938"
                  height="8.071"
                  viewBox="0 0 38.938 8.071"
                >
                  <g transform="translate(-1192.844 -692.433)">
                    <path
                      data-name="Icon open-arrow-left"
                      d="M3.561,0,0,3,3.561,5.935V3.561h34.1V2.374H3.561Z"
                      transform="translate(1231 699.427) rotate(180)"
                      fill="#da3338"
                    />
                    <path
                      data-name="Icon open-arrow-left - Outline"
                      d="M4.061-1.076v2.95h34.1V4.061H4.061V6.995L-.781,3.008Zm33.1,3.95H3.061v-1.8L.781,3l2.28,1.877V3.061h34.1Z"
                      transform="translate(1231 699.427) rotate(180)"
                      fill="#da3338"
                    />
                  </g>
                </svg>
              </Link>
            </div> */}
          </div>
        </div>
      </div>
      <div className="our_shape_block">
        <img src={workBg} alt="Shape" />
      </div>
    </div>
  )
}

export default OurWork
