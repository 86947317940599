import React from "react"

const DesignGuidelines = ({ data }) => {
  return (
    <div className="creative_design_guidelinessection_section">
      <div className="container">
        <div className="creative_design_guidelinessection_inner">
          <div className="commn_title_label">
            <p>{data.section8ShortText1}</p>
          </div>
          <div className="common_inner_title">
            <h3>{data.section8Title}</h3>
          </div>
          <div className="chance_dec cm-text-center">
            <p>{data.section8ShortText2}</p>
          </div>

          <div className="creative_design_row">
            {data.section8Links.map(link => (
              <a className="linkCreativeCardLink" href={link.url} target="_blank">
              <div className="creative_design_col" key={link.id}>
                <div className="creative_design_info">
                    <h3>{link.label}</h3>
                    <a href={link.url} target="_blank">
                    <b className="anim">download pdf</b>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8.071"
                        height="4.842"
                        viewBox="0 0 8.071 4.842"
                      >
                        <g
                          id="right-arrow"
                          transform="translate(8.071) rotate(90)"
                        >
                          <path
                            id="Icon_open-arrow-left"
                            data-name="Icon open-arrow-left"
                            d="M3.561,0,0,3,3.561,5.935V0Z"
                            transform="translate(4.061 6.995) rotate(180)"
                            fill="#bf1116"
                          />
                          <path
                            id="Icon_open-arrow-left_-_Outline"
                            data-name="Icon open-arrow-left - Outline"
                            d="M4.842,0V8.071L0,4.084Zm-1,3.95v-1.8L1.562,4.074l2.28,1.877v-2Z"
                            transform="translate(4.842 8.071) rotate(180)"
                            fill="#bf1116"
                          />
                        </g>
                      </svg>
                    </span>
                    </a>
                  </div>
                </div>
                </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DesignGuidelines
