import React, { useState, useEffect } from "react"

const CreativeVideo = ({ data }) => {
  // Array of all news articles
  const allLinks = data.section9Links

  // State for the list
  const [list, setList] = useState([...allLinks.slice(0, 4)])

  // State to trigger oad more
  const [loadMore, setLoadMore] = useState(false)

  // State of whether there is more to load
  const [hasMore, setHasMore] = useState(allLinks.length > 4)

  // Load more button click
  const handleLoadMore = () => {
    setLoadMore(true)
  }

  // Handle loading more articles
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length
      const isMore = currentLength < allLinks.length
      const nextResults = isMore
        ? allLinks.slice(currentLength, currentLength + 4)
        : []
      setList([...list, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore]) //eslint-disable-line

  //Check if there is more
  useEffect(() => {
    const isMore = list.length < allLinks.length
    setHasMore(isMore)
  }, [list]) //eslint-disable-line
  return (
    <div className="creative_video_section" id="creativevideos">
      <div className="container">
        <div className="creative_video_section_inner">
          <div className="common_section_title cm-text-center">
            <h2>
              {data.section9Title1} <span> {data.section9Title2}</span>
            </h2>
          </div>
          <div className="commbVideosWrapper">
          {list.map(item => (
            <div className="creative_video_playlist_wrap" key={item.id}>
              <div className="creative_video_playlist_inner">
                <iframe
                  src={item.url}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
              <p className="youtube_title">{item.label}</p>
            </div>
          ))}
          </div>
          {hasMore ? (
            <button onClick={handleLoadMore} className="common_btn loadVideosBtn">Load More
            <svg xmlns="http://www.w3.org/2000/svg" width="38.938" height="8.071" viewBox="0 0 38.938 8.071"><g id="right-arrow" transform="translate(-1192.844 -692.433)"><path id="Icon_open-arrow-left" data-name="Icon open-arrow-left" d="M3.561,0,0,3,3.561,5.935V3.561h34.1V2.374H3.561Z" transform="translate(1231 699.427) rotate(180)" fill="#fff"></path><path id="Icon_open-arrow-left_-_Outline" data-name="Icon open-arrow-left - Outline" d="M4.061-1.076v2.95h34.1V4.061H4.061V6.995L-.781,3.008Zm33.1,3.95H3.061v-1.8L.781,3l2.28,1.877V3.061h34.1Z" transform="translate(1231 699.427) rotate(180)" fill="#fff"></path></g></svg>
            </button>
          ) : (
            <p>No more results</p>
          )}
          {/* 
          <div className="creative_video_section_wrap">
            <div className="creative_left_video">
              <div className="creative_left_video_inner">
                <iframe
                  src={data.section9Links[0].url}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
              <p className="youtube_title">{data.section9Links[0].label}</p>
            </div>
            <div className="creative_video_playlist">
              <div className="creative_video_playlist_wrap">
                <div className="creative_video_playlist_inner">
                  <iframe
                    src={data.section9Links[1].url}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
                <p className="youtube_title">{data.section9Links[1].label}</p>
              </div>
              <div className="creative_video_playlist_wrap">
                <div className="creative_video_playlist_inner">
                  <iframe
                    src={data.section9Links[2].url}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
                <p className="youtube_title">{data.section9Links[2].label}</p>
              </div>
            </div>
          </div>
          <div className="common_section_btn view_all_btn">
            <a href={data.section9Links[3].url} className="common_btn">
              {data.section9Links[3].label}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="38.938"
                height="8.071"
                viewBox="0 0 38.938 8.071"
              >
                <g id="right-arrow" transform="translate(-1192.844 -692.433)">
                  <path
                    id="Icon_open-arrow-left"
                    data-name="Icon open-arrow-left"
                    d="M3.561,0,0,3,3.561,5.935V3.561h34.1V2.374H3.561Z"
                    transform="translate(1231 699.427) rotate(180)"
                    fill="#fff"
                  ></path>
                  <path
                    id="Icon_open-arrow-left_-_Outline"
                    data-name="Icon open-arrow-left - Outline"
                    d="M4.061-1.076v2.95h34.1V4.061H4.061V6.995L-.781,3.008Zm33.1,3.95H3.061v-1.8L.781,3l2.28,1.877V3.061h34.1Z"
                    transform="translate(1231 699.427) rotate(180)"
                    fill="#fff"
                  ></path>
                </g>
              </svg>
            </a>
          </div>
         */}
        </div>
      </div>
    </div>
  )
}

export default CreativeVideo
