import React from "react"
import { Link } from "gatsby"
import { animateScroll as scroll } from 'react-scroll'

const Tabs = ({ data }) => {
  return (
    <div>
      <div className="quick_tab_section">
        <div className="container">
          <div className="quick_slidemenu">
            <div className="quick_tab_inner">
              <div className="quick_tab_title">
                <div className="title_red_line">
                  <span>{data.section2Title}</span>
                </div>
              </div>
              <div className="wrapall_tab">
                <div className="all_quick_tabs">
                  {/* {data.section2Links.map(link => (
                    <Link to={link.url} className="tab_btn" key={link.id}>
                      <span>{link.label}</span>
                    </Link>
                  ))} */}
                  <div className="tab_btn" onClick={() => scroll.scrollTo(620)}>
                      <span>INDUSTRY INITIATIVES</span>
                  </div>
                  <div className="tab_btn" onClick={() => scroll.scrollTo(2500)}>
                      <span>Creative Library</span>
                  </div>
                  <div className="tab_btn" onClick={() => scroll.scrollTo(3200)}>
                      <span>OOH AWARDS</span>
                  </div>
                  <div className="tab_btn" onClick={() => scroll.scrollTo(4275)}>
                      <span>DESIGN GUIDELINES</span>
                  </div>
                  <div className="tab_btn" onClick={() => scroll.scrollTo(4700)}>
                      <span>CREATIVE VIDEOS</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Tabs
