import React, { useState } from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Modal from "react-modal"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const ObieAwards = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulObieAwards(limit: 12) {
        nodes {
          id
          image {
            gatsbyImageData
          }
          imageText
          title
          year
          subtitle
          category
          source
          award
          country
        }
      }
    }
  `)

  const { allContentfulObieAwards } = data

  const [idx, setIdx] = useState(null)
  const [modalIsOpen, setIsOpen] = useState(false)
  function openModal(i) {
    setIdx(i)
    setIsOpen(true)
  }
  function closeModal() {
    setIsOpen(false)
  }

  Modal.setAppElement("#___gatsby")
  return (
    <>
      <div className="obie_awards_section" id="obieawards">
        <div className="container">
          <div className="obie_awards_inner_section">
            <div className="common_section_title">
              <h2>
                Award-Winning <span>Creative</span>
              </h2>
            </div>
            <div className="cm_desc">
              <p>
                A collection of award-winning creative from around the world
              </p>
            </div>
            {/* <p className="cm_desc_bold">Here are the 2020 Canadian winners:</p> */}
          </div>

          <div className="ooh_awards_slider_section">
            <div className="obie_awards_blog_inner_section">
              <div className="obie_awards_blog_row">
                {allContentfulObieAwards.nodes.map((node, i) => (
                  <div className="obie_awards_blog_col obie_card" key={node.id}>
                    <div
                      className="obie_awards_blog_info"
                      onClick={() => openModal(i)}
                    >
                      <a href="#0" className="oa_blog_img">
                        <GatsbyImage
                          image={getImage(node.image)}
                          alt="blog"
                          className="slider_images"
                        />
                        <div className="oa_blog_winner">
                          <p className="oa_blog_winner_text">{node.year}</p>
                        </div>{" "}
                      </a>
                      <a href="#0" className="awards_btn sliver_color">
                        {node.award}
                      </a>

                      <div className="oa_blog_details">
                        <span className="oa_blog_sub_text">
                          {node.category}
                        </span>
                        <h3 className="oa_blog_title">{node.title}</h3>
                        <a href="#0" className="see_details_btn slider_images">
                          See Details
                          <i
                            className="fa fa-caret-right"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </div>
                    </div>
                    {modalIsOpen && (
                      <Modal
                        isOpen={idx === i}
                        onRequestClose={closeModal}
                        contentLabel={i.toString()}
                        id={i.toString()}
                        key={i}
                        testId={i.toString()}
                        bodyOpenClassName={`obieAwardsModal`}
                      >
                        <div className="modal-content">
                          <div className="modal_close_btn">
                            <span onClick={closeModal}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="17.688"
                                height="17.688"
                                viewBox="0 0 17.688 17.688"
                              >
                                <path
                                  id="close-icon"
                                  d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
                                  transform="translate(-7.5 -7.5)"
                                  fill="#fff"
                                ></path>
                              </svg>
                            </span>
                          </div>
                          <div className="modal_body">
                            <div className="casestudy-modal-content">
                              <div className="country_details_wrap">
                                <div className="country_details">
                                  <h1>{node.title}</h1>
                                  <p>{node.subtitle}</p>
                                </div>
                                <div className="country_years">
                                  <span>{node.year}</span>
                                </div>
                              </div>
                              <div className="modal_inner_img_wrap">
                                <GatsbyImage
                                  src={getImage(node.image)}
                                  alt="modal"
                                />
                                <div className="oa_blog_winner">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14.354"
                                    height="15.77"
                                    viewBox="0 0 14.354 15.77"
                                  >
                                    <path
                                      id="Icon_awesome-canadian-maple-leaf"
                                      data-name="Icon awesome-canadian-maple-leaf"
                                      d="M12.73,10.833c.077-.077,3.24-2.846,3.24-2.846l-.539-.231c-.308-.151-.228-.354-.154-.536.074-.234.619-2.073.619-2.073s-1.469.308-1.777.385c-.231.074-.308-.077-.385-.231s-.462-1-.462-1-1.62,1.845-1.7,1.919c-.308.231-.619,0-.542-.308,0-.308.85-3.992.85-3.992s-.927.536-1.235.69c-.231.154-.388.154-.542-.154C9.949,2.227,8.791,0,8.791,0S7.636,2.227,7.482,2.458c-.154.308-.308.308-.542.154-.308-.154-1.235-.69-1.235-.69s.85,3.684.85,3.992c.077.308-.231.539-.542.308-.077-.077-1.7-1.919-1.7-1.919s-.385.841-.462.995-.154.3-.385.231c-.311-.077-1.777-.385-1.777-.385s.542,1.839.619,2.073c.074.185.154.385-.154.536l-.539.234s3.16,2.769,3.24,2.846c.157.154.308.231.157.693S4.7,12.607,4.7,12.607s2.932-.619,3.243-.7c.268-.028.564.077.564.385s-.179,3.474-.179,3.474h.924s-.179-3.163-.179-3.474.293-.413.567-.385c.308.077,3.24.7,3.24.7s-.154-.619-.308-1.081,0-.539.154-.693Z"
                                      transform="translate(-1.617)"
                                      fill="#fff"
                                    ></path>
                                  </svg>
                                  <p className="oa_blog_winner_text">
                                    {node.imageText}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="obie_modal_content">
                              <div className="modal_source_wrap">
                                <div className="modal_source_info">
                                  <p>Source:</p>
                                </div>
                                <div className="modal_obie_awards_details">
                                  <p>{node.source}</p>
                                </div>
                              </div>
                              <div className="modal_source_wrap">
                                <div className="modal_source_info">
                                  <p>Product Category:</p>
                                </div>
                                <div className="modal_obie_awards_details">
                                  <p>{node.category}</p>
                                </div>
                              </div>
                              <div className="modal_source_wrap">
                                <div className="modal_source_info">
                                  <p>Creative Award:</p>
                                </div>
                                <div className="modal_obie_awards_details">
                                  <span className="modal_dots bronze_color">
                                    {" "}
                                  </span>
                                  <p>{node.award}</p>
                                </div>
                              </div>
                              <div className="modal_source_wrap">
                                <div className="modal_source_info">
                                  <p>Country:</p>
                                </div>
                                <div className="modal_obie_awards_details">
                                  <p>{node.country}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="common_section_btn view_all_btn load-more-div d-none-mobile">
            <Link to="/obie-awards" className="common_btn">
              View All
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="38.938"
                height="8.071"
                viewBox="0 0 38.938 8.071"
              >
                <g id="right-arrow" transform="translate(-1192.844 -692.433)">
                  <path
                    id="Icon_open-arrow-left"
                    data-name="Icon open-arrow-left"
                    d="M3.561,0,0,3,3.561,5.935V3.561h34.1V2.374H3.561Z"
                    transform="translate(1231 699.427) rotate(180)"
                    fill="#fff"
                  ></path>
                  <path
                    id="Icon_open-arrow-left_-_Outline"
                    data-name="Icon open-arrow-left - Outline"
                    d="M4.061-1.076v2.95h34.1V4.061H4.061V6.995L-.781,3.008Zm33.1,3.95H3.061v-1.8L.781,3l2.28,1.877V3.061h34.1Z"
                    transform="translate(1231 699.427) rotate(180)"
                    fill="#fff"
                  ></path>
                </g>
              </svg>
            </Link>
          </div>

          {/* <div className="loadmore_btn">
            <a href="#0" className="load_more" id="loadMore3">
              <i className="fa fa-plus" aria-hidden="true"></i>
            </a>
          </div> */}
        </div>
        <div id="designguidelines"></div>
      </div>
    </>
  )
}

export default ObieAwards
