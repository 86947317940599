import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/common/layout"
import Seo from "../components/common/seo"
import Hero from "../components/creative_centre/hero"
import Tabs from "../components/creative_centre/tabs"
// import OurSecondChance from "../components/creative_centre/second_chance"
import CreativeLibrary from "../components/creative_centre/creative_library"
import ObieAwards from "../components/creative_centre/obie_awards"
import DesignGuidelines from "../components/creative_centre/design_guidelines"
import CreativeVideo from "../components/creative_centre/creative_video"
import OurWork from "../components/home/our_work"

const CreativeCentre = ({ data }) => {
  
  const { creative, work } = data
  return (
    <div className="creativeCentrePage">
      <Layout>
        <Seo title="Creative Centre" />
        <div className="creative_center_page">
          <div className="main_content_wrapper">
            <Hero data={creative} />
            <Tabs data={creative} />
            <OurWork data={work} />
            {/* <OurSecondChance data={creative} /> */}
            <CreativeLibrary />
            <ObieAwards />
            <DesignGuidelines data={creative} />
            <CreativeVideo data={creative} />
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default CreativeCentre

export const query = graphql`
  {
    creative: contentfulCreativeCentre {
      section1Title
      section1Description {
        section1Description
      }
      section2Title
      section2Links {
        id
        label
        url
      }
      section4Title
      section4ShortText
      section4Content {
        raw
      }
      section4Images {
        id
        image {
          gatsbyImageData
        }
      }
      section8Title
      section8ShortText1
      section8ShortText2
      section8Links {
        id
        label
        url
      }
      section9Title1
      section9Title2
      section9Links {
        id
        label
        url
      }
    }
    work: contentfulHome {
      section6Title
      section6Projects {
        id
        title
        description {
          raw
        }
        image {
          gatsbyImageData
        }
      }
    }
  }
`
